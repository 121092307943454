<template>
  <div>
    <b-row class="match-height">
      <b-col
        v-for="(investor, index) in investors"
        :key="index"
        xs="12"
        md="4"
      >
        <b-card>
          <b-row>
            <b-col cols="auto">
              <b-avatar
                :text="investor.avatar_text"
                variant="primary"
                size="48"
              />
            </b-col>
            <b-col>
              <b-card-title class="mb-1">
                {{ investor.company }}
              </b-card-title>
              <b-card-sub-title>{{ investor.fullname }}</b-card-sub-title>
            </b-col>
          </b-row>
          <div class="mt-2">
            {{ investor.content }}
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BAvatar, BPagination,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BAvatar,
    BPagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 12,
      start: 0,
    }
  },
  computed: {
    investors() {
      return this.$store.getters['investors/getInvestors']
    },
    dataCount() {
      return this.$store.getters['investors/getInvestorsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getInvestors()
  },
  methods: {
    pagination(page) {
      this.start = page
      if (this.start === page) {
        this.getInvestors()
      }
    },
    getInvestors() {
      this.$store.dispatch('investors/investorsList', { start: this.start })
    },
  },
}
</script>
